<template>
    <div>
        <el-scrollbar class="set-container">
            <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="80px" class="edit-box">
                <div style="width: 60%">
                    <div class="info-title"><span>寄件人信息</span></div>
                    <div class="flex">
                        <el-form-item label="姓名" prop="send_username" style="width: 50%">
                            <el-input v-model="ruleForm.send_username"></el-input>
                        </el-form-item>
                        <el-form-item label="电话" prop="send_mobile" style="width: 50%">
                            <el-input v-model="ruleForm.send_mobile" autocomplete="new-password"></el-input>
                        </el-form-item>
                    </div>
                    <el-form-item label="地区" prop="value">
                        <el-cascader v-model="ruleForm.value" :options="areaArr" :props=keyObj clearable
                                     @change="(val) => (handleChange(val, 'send'))" style="width: 100%;">
                        </el-cascader>
                    </el-form-item>
                    <el-form-item label="详细地址" prop="send_detail_area">
                        <el-input v-model="ruleForm.send_detail_area" autocomplete="new-password"></el-input>
                    </el-form-item>
                </div>

                <div style="width: 60%">
                    <div class="info-title"><span>收件人信息</span></div>
                    <div class="flex">
                        <el-form-item label="姓名" prop="accept_username" style="width: 50%">
                            <el-input v-model="ruleForm.accept_username"></el-input>
                        </el-form-item>
                        <el-form-item label="电话" prop="accept_mobile" style="width: 50%">
                            <el-input v-model="ruleForm.accept_mobile" autocomplete="new-password"></el-input>
                        </el-form-item>
                    </div>
                    <el-form-item label="地区" prop="value1">
                        <el-cascader v-model="ruleForm.value1" :options="areaArr" :props=keyObj clearable
                                     @change="(val) => (handleChange(val, 'accept'))" style="width: 100%;">
                        </el-cascader>
                    </el-form-item>
                    <el-form-item label="详细地址" prop="accept_detail_area">
                        <el-input v-model="ruleForm.accept_detail_area" autocomplete="new-password"></el-input>
                    </el-form-item>
                </div>

                <div class="info-title" style="width: 60%"><span>包裹信息</span></div>
                <div class="flex">
                    <el-form-item label="物品类型" prop="category_id" style="width: 30%">
                        <el-select filterable v-model="ruleForm.category_id" placeholder="请选择">
                            <el-option v-for="item in categoryList" :key="item.category_id" :label="item.category_name" :value="item.category_id"></el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="物品重量" prop="weight" style="width: 30%">
                        <el-input-number class="input-number-left" v-model="ruleForm.weight" :controls="false" :min="0.1" :max="1000"></el-input-number>
                        <span style="margin-left: 6px;">KG</span>
                    </el-form-item>
                </div>

                <div class="info-title" style="width: 60%"><span>物流信息</span></div>
                <div class="flex">
                    <el-form-item label="快递公司" prop="mail_company_id" style="width: 30%">
                        <el-select filterable v-model="ruleForm.mail_company_id" placeholder="请选择">
                            <el-option v-for="item in companyList" :key="item.id" :label="item.name" :value="item.id"></el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="快递单号" prop="mail_no" style="width: 30%">
                        <el-input v-model="ruleForm.mail_no"></el-input>
                    </el-form-item>
                    <el-form-item label="运费" prop="mail_fee" style="width: 30%">
                        <el-input-number class="input-number-left" v-model="ruleForm.mail_fee" :controls="false" :min="0.01" :max="10000"></el-input-number>
                        <span style="margin-left: 6px">元</span>
                    </el-form-item>
                </div>

                <el-form-item>
                    <el-button @click="goBack">返回</el-button>
                    <el-button type="primary" @click="submitForm('ruleForm')">保存</el-button>
                </el-form-item>
            </el-form>
        </el-scrollbar>
    </div>
</template>

<script>
    import { mail_manageInfo, categoryList, courier_companyIndex, mail_manageEdit } from '@/config/apis.js'
    import {areaList} from '@/utils/area'

    export default {
        name: "SendingSee",
        data() {
            let checkContact = (rule, value, callback) => {
                if (!value) {
                    return callback(new Error('请输入手机号'));
                }
                if (/^1[3456789]\d{9}$/.test(value) === false) {
                    return callback(new Error('请输入正确的手机格式'));
                }
                return callback();
            }
            return {
                areaArr: [],
                keyObj: {value: 'areaName', label: 'areaName', children: 'children', checkStrictly: true},
                categoryList: [],
                companyList: [],
                ruleForm: {
                    id: '',
                    send_username: '',
                    send_mobile: '',
                    value: [],
                    send_province: '',
                    send_city: '',
                    send_district: '',
                    send_detail_area: '',

                    accept_username: '',
                    accept_mobile: '',
                    value1: [],
                    accept_province: '',
                    accept_city: '',
                    accept_district: '',
                    accept_detail_area: '',

                    category_id: '',
                    weight: void 0,

                    mail_company_id: '',
                    mail_no: '',
                    mail_fee: void 0,
                },
                rules: {
                    send_username: { required: true, message: '请输入寄件人姓名', trigger: 'blur' },
                    // send_mobile: { required: true, message: '请输入寄件人手机号', trigger: 'blur' },
                    send_mobile: { required: true, validator: checkContact },
                    value: {required: true, message: '请选择省市区', trigger: 'change'},
                    send_detail_area: {required: true, message: '请输入详细地址', trigger: 'blur'},

                    accept_username: { required: true, message: '请输入收件人姓名', trigger: 'blur' },
                    // accept_mobile: { required: true, message: '请输入收件人手机号', trigger: 'blur' },
                    accept_mobile: { required: true, validator: checkContact },
                    value1: {required: true, message: '请选择省市区', trigger: 'change'},
                    accept_detail_area: {required: true, message: '请输入详细地址', trigger: 'blur'},
                },
            }
        },
        mounted() {
            this.getInfo()
            this.getCategoryList()
            this.getCompanyList()
            this.areaArr = areaList
        },
        methods: {
            // 获取详情
            getInfo() {
                let id = Number(this.$route.query.id)
                mail_manageInfo(id).then(res => {
                    this.ruleForm = {
                        id: res.data.id,
                        send_username: res.data.getMailArea.send_username,
                        send_mobile: res.data.getMailArea.send_mobile,
                        value: [res.data.getMailArea.send_province, res.data.getMailArea.send_city],
                        send_province: res.data.getMailArea.send_province,
                        send_city: res.data.getMailArea.send_city,
                        send_district: res.data.getMailArea.send_district ? res.data.getMailArea.send_district : '',
                        send_detail_area: res.data.getMailArea.send_detail_area,

                        accept_username: res.data.getMailArea.accept_username,
                        accept_mobile: res.data.getMailArea.accept_mobile,
                        value1: [res.data.getMailArea.accept_province, res.data.getMailArea.accept_city],
                        accept_province: res.data.getMailArea.accept_province,
                        accept_city: res.data.getMailArea.accept_city,
                        accept_district: res.data.getMailArea.accept_district ? res.data.getMailArea.accept_district : '',
                        accept_detail_area: res.data.getMailArea.accept_detail_area,

                        category_id: res.data.category_id ? res.data.category_id : '',
                        weight: res.data.weight ? res.data.weight : void 0,

                        mail_company_id: res.data.mail_company_id ? res.data.mail_company_id : '',
                        mail_no: res.data.mail_no ? res.data.mail_no : '',
                        mail_fee: res.data.mail_fee ? res.data.weight : void 0,
                    }
                    if (res.data.getMailArea.send_district) {
                        this.$set(this.ruleForm.value, 2, res.data.getMailArea.send_district)
                    }
                    if (res.data.getMailArea.accept_district) {
                        this.$set(this.ruleForm.value1, 2, res.data.getMailArea.accept_district)
                    }
                }).catch(err => {})
            },
            // 保存
            submitForm(formName) {
                if (this.ruleForm.value.length < 2) {
                    return this.$message.warning('请选择寄件人城市！')
                }
                if (this.ruleForm.value1.length < 2) {
                    return this.$message.warning('请选择收件人城市！')
                }
                this.$refs[formName].validate((valid) => {
                    if (valid) {
                        let formData = new FormData()
                        formData.append('id', this.ruleForm.id)
                        formData.append('send_province', this.ruleForm.send_province)
                        formData.append('send_city', this.ruleForm.send_city)
                        formData.append('send_detail_area', this.ruleForm.send_detail_area)
                        formData.append('send_mobile', this.ruleForm.send_mobile)
                        formData.append('send_username', this.ruleForm.send_username)
                        formData.append('accept_province', this.ruleForm.accept_province)
                        formData.append('accept_city', this.ruleForm.accept_city)
                        formData.append('accept_detail_area', this.ruleForm.accept_detail_area)
                        formData.append('accept_mobile', this.ruleForm.accept_mobile)
                        formData.append('accept_username', this.ruleForm.accept_username)

                        if (this.ruleForm.mail_no) {
                            formData.append('mail_no', this.ruleForm.mail_no)
                        }
                        if (this.ruleForm.mail_company_id) {
                            formData.append('mail_company_id', this.ruleForm.mail_company_id)
                        }
                        if (this.ruleForm.category_id) {
                            formData.append('category_id', this.ruleForm.category_id)
                        }
                        if (this.ruleForm.mail_fee !== void 0) {
                            formData.append('mail_fee', this.ruleForm.mail_fee)
                        }
                        if (this.ruleForm.send_district) {
                            formData.append('send_district', this.ruleForm.send_district)
                        }
                        if (this.ruleForm.accept_district) {
                            formData.append('accept_district', this.ruleForm.accept_district)
                        }
                        if (this.ruleForm.weight !== void 0) {
                            formData.append('weight', this.ruleForm.weight)
                        }

                        mail_manageEdit(formData).then(res => {
                            this.$message.success(res.msg)
                            this.$router.push('/school/expressManage/sending')
                        }).catch(err => {})
                    } else {
                        return false;
                    }
                });
            },
            // 返回
            goBack() {
                this.$router.push('/school/expressManage/sending')
            },
            // 获取商品分类
            getCategoryList() {
                categoryList({paging: 0}).then(res => {
                    this.categoryList = res.data.list
                }).catch(err => {})
            },
            // 获取快递公司
            getCompanyList() {
                courier_companyIndex().then(res => {
                    this.companyList = res.data.list
                }).catch(err => {})
            },

            handleChange(val, type) {
                if (type === 'send' && val.length > 0) {
                    this.ruleForm.send_province = val[0]
                    this.ruleForm.send_city = val[1] ? val[1] : ''
                    this.ruleForm.send_district = val[2] ? val[2] : ''
                }
                if (type === 'accept' && val.length > 0) {
                    this.ruleForm.accept_province = val[0]
                    this.ruleForm.accept_city = val[1] ? val[1] : ''
                    this.ruleForm.accept_district = val[2] ? val[2] : ''
                }
            }
        }
    }
</script>

<style scoped lang="scss">
    ::v-deep .set-container {
        margin: 20px;
        background: #fff;
        height: calc(100% - 40px);
        border: 1px solid #e6e6e6;
        border-radius: 4px;
        & > .el-scrollbar__wrap {
            overflow-x: hidden;
        }
    }
    .edit-box {
        padding: 20px;
        /*width: 60%;*/
        box-sizing: border-box;
    }
    .info-title {
        /*color: #409eff;*/
        line-height: 40px;
        font-size: 18px;
        text-align: center;
        font-weight: 500;
        position: relative;
        margin-bottom: 10px;
        span {
            background: #fff;
            padding: 0 20px;
            z-index: 1;
            position: relative;
        }
        &:after {
            content: '';
            position: absolute;
            top: 50%;
            left: 0;
            right: 0;
            height: 1px;
            background: #e6e6e6;
            transform: translateY(-50%);
            z-index: 0;
        }
    }
    .flex {
        display: flex;
    }
</style>